import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Text,
  Avatar,
  CardHeader as ChakraCardHeader,
} from '@chakra-ui/react';

export default function CardHeader({
  title,
  text = null,
  icon = null,
  image = null,
  imageAlt = null,
  roundedImage = null,
  padding = null,
  iconLeftForXs = null,
  avatar = null,
  author = null,
  homeFontSize = null,
}) {
  return (
    <ChakraCardHeader p={padding ? padding : '0'}>
      <Grid
        templateColumns={(image || avatar) ? ['4rem 1fr', '', '6rem 1fr'] : null}
        templateRows={(image || avatar) && text ? ['1fr 2.25rem', '', 'auto auto'] : null}
        gap={(image || avatar || text) ? '0.5rem 1rem' : null}
      >
        {image && (
          <GridItem rowSpan={{ md: '2' }}>
            <Box width={['4rem', '', '6rem']} height={['4rem', '', '6rem']} alignContent="center">
              <Image
                src={image}
                alt={imageAlt}
                height="100%"
                width="100%"
                objectFit="cover"
                borderRadius={roundedImage ? 'full' : 'none'}
              />
            </Box>
          </GridItem>
        )}
        {avatar && (
          <GridItem rowSpan={{ md: '2' }}>
            <Box width={['4rem', '', '6rem']} height={['4rem', '', '6rem']} alignContent="center">
              <Avatar
                src={avatar ? avatar : null}
                name={author ? author : null}
                width={{ base: "4rem", md: "6rem" }}
                height={{ base: "4rem", md: "6rem" }}
                aspectRatio={1}
              />
            </Box>
          </GridItem>
        )}

        <GridItem rowSpan={{ md: !text ? '2' : '1' }} alignContent="center">
          <Flex justify={{ base: "space-between", sm: "flex-start" }} align="center" gap={{ base: ".625rem",  md: "1rem" }}>
            <Heading
              as="h3"
              fontSize={{ base: "1.5rem", md: homeFontSize ? "1.8125rem" : "1.5rem" }}
              wordBreak="break-word"
              lineHeight="1.2em"
              flexGrow={iconLeftForXs ? 1 : null}
            >
              {title}
            </Heading>
            {icon && <Icon as={icon} boxSize={{ base: "2rem", md: "2.5rem" }} color="brand.100" order={{ base: iconLeftForXs ? -1 : null, md: 1 }} />}
          </Flex>
        </GridItem>

        {text && (
          <GridItem colSpan={['2', '', '1']} alignContent="center">
            <Text fontSize={['0.75rem', '', '1rem']} noOfLines="2">
              {text}
            </Text>
          </GridItem>
        )}
      </Grid>
    </ChakraCardHeader>
  );
}
