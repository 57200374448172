import { Flex, Icon, Text } from '@chakra-ui/react';
import {
  PiCaretDownThin,
  PiCaretUpThin,
  PiChatCircleThin,
  PiHeartThin,
  PiShareNetworkThin,
} from 'react-icons/pi';

export default function PublicationSocials({
  likes = null,
  shares = null,
  comments = null,
  showCommentSection = null,
  onShowIconClick = null,
  hideComments = null,
  isIconFirst = null,
  iconWidth = null,
  iconColor = null,
}) {
  const MobileDisplayForNullElements = ({
    label,
    icon,
    iconWidth,
    iconColor,
  }) => {
    return (
      <>
        <Text as="span" display={['none', 'block']}>
          {label}
        </Text>
        <Icon
          as={icon}
          boxSize={iconWidth ? iconWidth : '1.25rem'}
          color={iconColor ? iconColor : null}
          ml=".25rem"
        />
      </>
    );
  };

  const MobileDisplay = ({ items, label, icon, iconWidth, iconColor }) => {
    return (
      <>
        <Text as="span" display={['none', 'block']} mr=".25rem">
          {label}
          {items > 1 && 's'}
        </Text>
        <Icon
          as={icon}
          boxSize={iconWidth ? iconWidth : '1.25rem'}
          color={iconColor ? iconColor : null}
        />
      </>
    );
  };
  return (
    <Flex
      justify="space-between"
      lineHeight={iconWidth ? iconWidth : '1.25rem'}
      gap="1rem"
    >
      <Flex align="center" color="black.75" gap=".5ch">
        <Icon
          as={PiHeartThin}
          boxSize={iconWidth ? iconWidth : '1.25rem'}
          color={iconColor ? iconColor : null}
        />
        <Text fontSize="0.75rem" display="inline-flex" gap="0.25rem">
          {likes}{' '}
          <Text as="span" display={['none', 'block']}>
            J'aime
          </Text>
        </Text>
      </Flex>

      <Flex align="center" color="black.75">
        {shares ? (
          <Text
            fontSize="0.75rem"
            display="inline-flex"
            gap="0.25rem"
            flexDirection={isIconFirst ? 'row-reverse' : null}
          >
            {!isIconFirst && shares}
            <MobileDisplay
              items={shares}
              label={isIconFirst ? shares + ' partage' : 'partage'}
              icon={PiShareNetworkThin}
              iconWidth={iconWidth ? iconWidth : null}
              iconColor={iconColor ? iconColor : null}
            />
          </Text>
        ) : (
          <Text fontSize="0.75rem" display="inline-flex" gap="0.25rem">
            0
            <MobileDisplayForNullElements
              label="partage"
              icon={PiShareNetworkThin}
              iconWidth={iconWidth ? iconWidth : null}
              iconColor={iconColor ? iconColor : null}
            />
          </Text>
        )}
        {comments > 0 && !hideComments && (
          <>
            <Text as="span" pb="0.3333rem" mx="1rem">
              •
            </Text>
            <Flex
              align="center"
              cursor="pointer"
              textDecoration="underline"
              _hover={{
                textDecoration: 'none',
              }}
              onClick={() => onShowIconClick(!showCommentSection)}
            >
              <Text as="span" fontSize="0.75rem" display="inline-flex" flexWrap="nowrap">
                <Text as="span" display="inline-block" mr={{ base: '.25rem', sm: 'revert' }}>
                  {comments}
                  <Text as="span" display={{ base: 'none', sm: 'inline' }}>
                    &nbsp;
                  </Text>
                </Text>
                <MobileDisplay
                  items={comments}
                  label="commentaire"
                  icon={PiChatCircleThin}
                />
              </Text>
              <Icon
                as={showCommentSection ? PiCaretUpThin : PiCaretDownThin}
                mt="0.15rem"
              />
            </Flex>
          </>
        )}
        {!comments && !hideComments &&(
          <>
            <Text as="span" pb="0.3333rem" mx="1rem">
              •
            </Text>
            <Text as="span" fontSize="0.75rem" display="inline-flex" flexWrap="nowrap">
              0
              <span>
                &nbsp;
              </span>
              <MobileDisplayForNullElements
                label="commentaire"
                icon={PiChatCircleThin}
              />
            </Text>
          </>
        )}
      </Flex>
    </Flex>
  );
}
