import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import SimpleCard from './SimpleCard';
import CardHeader from './Parts/CardHeader';
import CardBody from './Parts/CardBody';
import CardFooter from './Parts/CardFooter';
import PublicationSocials from '../Publications/PublicationSocials';
import {
  PiBookmarkSimpleThin,
  PiChartBarThin,
  PiListChecksThin,
  PiShapesThin,
} from 'react-icons/pi';
import NextLink from 'next/link';

export default function HomeFeaturedCard({
  color = null,
  index = null,
  title,
  avatar,
  author = null,
  date = null,
  resume = null,
  themes = null,
  levels = null,
  categories = null,
  skills = null,
  likes = null,
  shares = null,
  href,
  url,
  detailButtonLabel = 'Voir la ressource',
  width = null,
  buttonVariant = null,
  mt = null,
  cardFooterOnTwoLinesForSm = null,
  cardFooterOnTwoLinesForMd = null,
  buttonSize = 'md',
  badge = null,
}) {
  return (
    <SimpleCard
      accentColor={color}
      key={index}
      height={{ md: '100%' }}
      width={width ? width : { base: 'calc(100vw - 4rem)', md: 'revert' }}
      mt={mt}
    >
      {badge ? (
        <Flex
          direction={{ base: 'column-reverse', sm: 'revert' }}
          gap={{ base: '1rem', sm: 'revert' }}
          justify="space-between"
        >
          <CardHeader
            title={title}
            avatar={avatar ? avatar : 'avatarPlaceholder'}
            author={author}
          />
          <Flex justify="end">{badge}</Flex>
        </Flex>
      ) : (
        <CardHeader
          title={title}
          avatar={
            avatar ? avatar : 'avatarPlaceholder'
          } /* !avatar && avatar!=null will display user's initials in a circle */
          author={author}
        />
      )}
      <CardBody>
        <Text fontSize="0.75rem" mb="1rem">
          Publié par {author} le {date}.
        </Text>
        <Box mb="1rem">{resume}</Box>
        <Grid
          gap=".5rem"
          gridRowGap={{ md: '.75rem' }}
          align="stretch"
          display={{ base: 'block', md: 'grid' }}
          templateColumns={{ md: '1fr 2fr' }}
        >
          {themes && themes.length > 0 && (
            <Box display={{ md: 'contents' }} mb={{ base: '.5rem', md: 0 }}>
              <GridItem
                color="brand.100"
                fontWeight="600"
                display={{ base: 'inline', md: 'revert' }}
              >
                <HStack spacing=".5rem">
                  <Icon
                    as={PiBookmarkSimpleThin}
                    boxSize="2rem"
                    display={{ base: 'none', md: 'inline-block' }}
                  />
                  <span>
                    Thème
                    {themes.length > 1 && <>s</>}
                    <Box as="span" display={{ md: 'none' }}>
                       :
                    </Box>{' '}
                  </span>
                </HStack>
              </GridItem>
              <GridItem
                display={{ base: 'inline', md: 'revert' }}
                pt={{ md: '.25rem' }}
              >
                <List display="inline">
                  {themes.map((theme, index) => (
                    <ListItem key={index} display="inline">
                      {theme}
                      {index < themes.length - 1 && ', '}
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            </Box>
          )}
          {levels && levels.length > 0 && (
            <Box display={{ md: 'contents' }} mb={{ base: '.5rem', md: 0 }}>
              <GridItem
                color="brand.100"
                fontWeight="600"
                display={{ base: 'inline', md: 'revert' }}
              >
                <HStack spacing=".5rem">
                  <Icon
                    as={PiChartBarThin}
                    boxSize="2rem"
                    display={{ base: 'none', md: 'inline-block' }}
                  />
                  <span>
                    Niveau
                    {levels.length > 1 && <>x</>}
                    <Box as="span" display={{ md: 'none' }}>
                       :
                    </Box>{' '}
                  </span>
                </HStack>
              </GridItem>
              <GridItem
                display={{ base: 'inline', md: 'revert' }}
                pt={{ md: '.25rem' }}
              >
                <List display="inline">
                  {levels.map((level, index) => (
                    <ListItem key={index} display="inline">
                      {level}
                      {index < levels.length - 1 && ', '}
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            </Box>
          )}
          {categories && categories.length > 0 && (
            <Box display={{ md: 'contents' }} mb={{ base: '.5rem', md: 0 }}>
              <GridItem
                color="brand.100"
                fontWeight="600"
                display={{ base: 'inline', md: 'revert' }}
              >
                <HStack spacing=".5rem">
                  <Icon
                    as={PiShapesThin}
                    boxSize="2rem"
                    display={{ base: 'none', md: 'inline-block' }}
                  />
                  <span>
                    Catégorie
                    {categories.length > 1 && <>s</>}
                    <Box as="span" display={{ md: 'none' }}>
                       :
                    </Box>{' '}
                  </span>
                </HStack>
              </GridItem>
              <GridItem
                display={{ base: 'inline', md: 'revert' }}
                pt={{ md: '.25rem' }}
              >
                <List display="inline">
                  {categories.map((category, index) => (
                    <ListItem key={index} display="inline">
                      {category}
                      {index < categories.length - 1 && ', '}
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            </Box>
          )}
          {skills && skills.length > 0 && (
            <Box display={{ md: 'contents' }} mb={{ base: '.5rem', md: 0 }}>
              <GridItem
                color="brand.100"
                fontWeight="600"
                display={{ base: 'inline', md: 'revert' }}
              >
                <HStack spacing=".5rem">
                  <Icon
                    as={PiListChecksThin}
                    boxSize="2rem"
                    display={{ base: 'none', md: 'inline-block' }}
                  />
                  <span>
                    Compétence
                    {skills.length > 1 && <>s</>}
                    <Box as="span" display={{ md: 'none' }}>
                       :
                    </Box>{' '}
                  </span>
                </HStack>
              </GridItem>
              <GridItem
                display={{ base: 'inline', md: 'revert' }}
                pt={{ md: '.25rem' }}
              >
                <List display="inline">
                  {skills.map((skill, index) => (
                    <ListItem key={index} display="inline">
                      {skill}
                      {index < skills.length - 1 && ', '}
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            </Box>
          )}
        </Grid>
      </CardBody>
      <CardFooter>
        <Stack
          w="100%"
          direction={{
            base: 'column',
            sm: !cardFooterOnTwoLinesForSm ? 'row' : null,
            md: !cardFooterOnTwoLinesForMd ? 'row' : 'column',
          }}
          justify={{ sm: 'space-between' }}
          align={{ md: cardFooterOnTwoLinesForMd ? 'stretch' : 'center' }}
          spacing={{ md: cardFooterOnTwoLinesForMd && url ? '1.25rem' : null }}
        >
          <Box display={{ base: 'none', sm: 'revert' }}>
            <PublicationSocials
              likes={likes}
              shares={shares}
              hideComments
              isIconFirst
              iconWidth="2rem"
              iconColor="brand.100"
            />
          </Box>
          {url && (
            <Flex width={{ base: '100%', sm: 'revert' }}>
              <NextLink href={href} as={url} passHref>
                <Button
                  size={buttonSize}
                  lineHeight="1.2em"
                  width={{
                    base: '100%',
                    sm: cardFooterOnTwoLinesForSm ? 'auto' : null,
                    md: cardFooterOnTwoLinesForMd ? 'auto' : null,
                  }}
                  variant={buttonVariant ? buttonVariant : 'tile'}
                  as="a"
                  px={{ md: '1.5em' }}
                  marginLeft={{
                    sm: cardFooterOnTwoLinesForSm ? 'auto' : null,
                    md: cardFooterOnTwoLinesForMd ? 'auto' : null,
                  }}
                  my={{
                    sm: cardFooterOnTwoLinesForSm ? '.25rem' : null,
                    md: null,
                  }}
                >
                  {detailButtonLabel}
                </Button>
              </NextLink>
            </Flex>
          )}
        </Stack>
      </CardFooter>
    </SimpleCard>
  );
}
